@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

html {
    font-size: 14px; /* Adjust this to a smaller base font size for the entire app */
}

.WorkExperience {
    text-align: left;
    padding: 0px 100px 100px 100px;
    background: transparent;
    color: #00BFFF;
    gap: 20px;
    opacity: 0;
    transform: translateX(300px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.WorkExperience.visible {
    opacity: 1;
    transform: translateX(0);
}

.WorkExperience-Header {
    font-size: 2rem; /* Adjusted from 30px */
    color: white;
    padding: 0px 0px 25px 0px;
}

.WorkExperience-SubHeader {
    font-size: 0.875rem; /* Adjusted from 15px */
    color: #00BFFF;
    padding: 0px 0px 25px 0px;
}

.WorkExperience-Container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Creates a responsive number of column tracks */
    grid-template-rows: minmax(100px, auto);
    gap: 20px;
    align-items: stretch;
}

.WorkExperience-Card-Link {
    text-decoration: none;
    color: inherit;
    display: block;
}

.WorkExperience-Card {
    display: flex;
    flex-direction: row;
    background: #002244;
    border-radius: 10px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transform: translateX(300px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out, box-shadow 0.3s ease-out;
    padding: 20px;
}

.WorkExperience-Card.visible {
    opacity: 1;
    transform: translateX(0);
}

.WorkExperience-Card:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 5px 5px rgba(0, 191, 255, 0.6);
}

.WorkExperience-Card ul {
    list-style-type: disc;
    margin-left: 20px;
}

.WorkExperience-Card li {
    margin-bottom: 8px;
}

.WorkExperience-Image {
    width: 50px;
    height: 50px;
    border-radius: 10px;
}

.WorkExperience-Content {
    overflow: hidden;
    color: white;
}

.WorkExperience-Link {
    background: none;
    border: none;
    color: inherit;
    margin: 0 1rem;
    padding: 0.5rem 1rem;
    font-size: 0.875rem; /* Adjust this to decrease button text size */
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
