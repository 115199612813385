.Project {
    text-align: left;
    padding: 0px 100px 100px 100px;
    background: transparent;
    color: #00BFFF;
    gap: 20px;
    opacity: 0;
    transform: translateX(-100px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.Project.visible {
    opacity: 1;
    transform: translateX(0);
}

.Project-Header {
    font-size: 30px;
    color: white;
    padding: 0px 0px 25px 0px;
}

.Project-Container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-rows: minmax(100px, auto);
    gap: 20px;
    align-items: stretch;
}

.Project-Card-Link {
    text-decoration: none;
    color: inherit;
    display: block;
}

.Project-Card {
    display: flex;
    flex-direction: column;
    background: #002244;
    border-radius: 10px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transform: translateX(-100px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out, box-shadow 0.3s ease-out;
    padding: 20px;
}

.Project-Card.visible {
    opacity: 1;
    transform: translateX(0);
}

.Project-Card:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 5px 5px rgba(0, 191, 255, 0.6);
}

.Project-Card ul {
    list-style-type: disc;
    margin-left: 20px;
}

.Project-Card li {
    margin-bottom: 8px;
}

.Project-Title {
    font-size: 24px;
    margin: 10px 0;
}

.Project-Subheader {
    font-size: 18px;
    color: #00BFFF;
    margin: 5px 0;
}

.Project-Content {
    overflow: hidden;
    flex-grow: 1;
    padding: 10px;
}

.Button-Container {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

.Project-Button {
    background: none;
    border: 2px solid #00BFFF;
    color: #00BFFF;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
}

.Project-Button:hover {
    background-color: #00BFFF;
    color: #002244;
}

.Project-Button .fa-github {
    margin-right: 8px;
    font-size: 20px; /* Adjust this to make the GitHub logo larger */
}
