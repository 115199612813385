.About {
  display: flex;
  padding: 0px 100px 100px 100px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: 100hv;
  background-color: transparent;
  opacity: 0;
  transform: translateX(-300px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.About.visible {
  opacity: 1;
  transform: translateX(0);
}

.About-Container .Intro-Text {
  font-size: 1.5em;
  color: #00BFFF;
}

.About-Container p {
  font-size: 1.2em;
  color: white;
}

.About-Container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
  padding: 20px;
  width: 90vw; /* Set width to 90% of the viewport width */
  max-width: 90vw; /* Ensure max-width is also 90% of the viewport width */
}

.Tab-Content ul {
  list-style-position: inside;
  padding-left: 20px; /* Adjust this value to align the dots properly */
}

.About-Header {
  font-size: 60px;
}

.Tab {
  display: flex;
  margin-bottom: 1rem;
}

.Tab-Button {
  background: none;
  border: none;
  color: inherit;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
  overflow: hidden;
  font-size: 1.25em;
}

.Tab-Button::after {
  content: '';
  position: absolute;
  height: 2px;
  left: 0;
  bottom: 0;
  width: 0;
  background: #3498db;
  transition: width 0.3s ease;
  transform-origin: left;
}

.Tab-Button:hover::after, .Tab-Button.active::after {
  width: 100%;
}

.Tab-Button:hover, .Tab-Button.active {
  color: #3498db;
}

.Tab-Content {
  padding: 1rem;
  border-radius: 8px;
  background: #002244;
  min-height: 300px; /* Existing min-height */
  color: white;
  text-align: left;
  height: 400px; /* Set a fixed height to accommodate the largest content */
  overflow-y: auto; /* Add this to allow scrolling if content overflows */
}

.Image-Container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Responsive-Image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.blue-text {
  color: #00BFFF;
  font-weight: bold;
}
