.footer {
  background-color: #002244; /* Adjust the background color as needed */
  color: #00BFFF;
  text-align: center;
  padding: 15px;
  font-size: 16px;
}

.footer-content {
  display: inline-block;
  align-items: center;
}

.footer-link {
  color: #00BFFF;
  text-decoration: none;
  margin-left: 5px;
  transition: color 0.3s;
}

.footer-link:hover {
  color: #1E90FF; /* Adjust the hover color if needed */
}