.Home {
  position: relative;
  height: 100vh;
  color: white;
  background: rgb(10, 25, 40); /* Consistent dark blue-grey background */
}

.HeaderText {
  position: absolute;
  top: 50%;  /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%);
  z-index: 10; /* Ensure visibility above the canvas */
  font-size: 4em; /* Large text size */
  color: #00BFFF; /* Bright color for visibility */
  text-align: center; /* Center the text inside the div */
  width: 2000px; /* Fixed width sufficient to accommodate the text */
  font-family: 'Courier New', Courier, monospace; /* Monospaced font */
}


.MatrixCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.Home::after { /* Gradient overlay at the bottom */
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  background: linear-gradient(transparent, rgb(10, 25, 40));
  z-index: 2;
}
