.Contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    color: #00BFFF;
    background-color: transparent;
    opacity: 0;
    transform: translateX(400px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    padding: 0px 100px 100px 100px;
}

.Contact.visible {
    opacity: 1;
    transform: translateX(0);
}

.Contact-Header {
    font-size: 30px;
    color: white;
    padding: 50px 0px 50px 0px;
}

.Contact-Container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    max-width: 1200px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #002244;
    height: fit-content;
    transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
}

.Contact-Container:hover {
    box-shadow: 0 0 5px 5px rgba(0, 191, 255, 0.6);
}

.Contact-Form, .Contact-Info {
    width: 50%;
    text-align: center;
}

.Contact-Form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Contact-Input, .Contact-Button, .Download-Button {
    background: none;
    border: 2px solid #00BFFF;
    color: #00BFFF;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
}

.Contact-Button:hover, .Download-Button:hover {
    background-color: #00BFFF;
    color: #002244;
}

.Contact-Input {
    background-color: white;
    color: black;
    height: 50px;
    width: 80%;
    font-size: 15px;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    text-align: left;
}

.contact-textarea {
    height: 100px;
    resize: none;
}

.Contact-Info {
    flex: 1;
    padding: 20px;
    background: #001122;
    border-radius: 10px;
    color: #00BFFF;
}

.Contact-Info p, .social-links a {
    font-size: 1.2em;
    margin-bottom: 10px; /* Add margin to create space between items */
}

.social-links {
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-links a {
    margin: 0 10px; /* Adjust margin to align items */
    font-size: 2em; /* Increase the size of the icons */
    color: white;
}

.social-links a:hover {
    color: #3498db;
}

.Download-Button {
    margin-left: 10px; /* Adjust margin to align items */
    padding: 10px 20px; /* Adjust padding to make the button smaller */
    font-size: 1em; /* Adjust font size */
}

.char-count {
    font-size: 12px;
    color: #00BFFF;
    text-align: right;
    width: 80%;
    margin-bottom: 10px;
    display: none;
}

.contact-textarea:focus + .char-count {
    display: block;
}
